import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CheckAccess } from "../common/utils/roleAccess";
import { Accordion } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import config from "../../config";

const Menu = () => {
  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const token = sessionStorage.getItem("token");

  const token1 = localStorage.getItem("token");
  useEffect(() => {
    if (token1 !== null) {
      // navigate(`/dashboard`);
    } else {
      navigate(`/login`);
    }
  }, [token1]);

  const [settings, setSettings] = useState(null)

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(config.apiUrl + '/general-settings')
      const data = await response.json()
      setSettings(data)
    }
    getData()
  }, [])

  return (
    <>
      <aside className="main-sidebar">
        {/* <!-- sidebar --> */}
        <section className="sidebar">
          {/* <!-- Sidebar user panel --> */}
          <div className="user-panel">
            <div className="ulogo">
              <a href="/story">
                {/* <!-- logo for regular state and mobile devices --> */}
                <span>
                  <b>RAFTAAR </b>CMS
                </span>
              </a>
            </div>
            <a href='/story'>
              <div className="image">
                {/* <img
                src="../images/user2-160x160.jpg"
                className="rounded-circle"
                alt="User Image"
              /> */}

                <div style={{ textAlign: 'center' }}>
                  {/* <FaUser style={{ height: '50px', width: '50px' }} /> */}

                  <img src={settings?.cmsLogo} style={{ height: '50px', width: '50px', borderRadius: '5px', cursor: 'pointer' }} />

                </div>
              </div>
            </a>
            <div className="info">
              <p className="text-capitalize role-title">
                {user?.user_role?.name ? user?.user_role?.name : ""}
              </p>
              <p>{user ? user.name : ""}</p>
              {/* <div
                className={`d-flex justify-content-center align-items-center`}
              >
                <a
                  href=""
                  className="link"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Settings"
                >
                  <i className="fa fa-cog"></i>
                </a>
                <span className="mx-2"></span>
                <div
                  className="link"
                  title=""
                  role="button"
                  onClick={() => {
                    navigate("/login");
                    sessionStorage.clear();
                    localStorage.clear();
                  }}
                >
                  <i className="fa fa-power-off"></i>
                </div>
              </div> */}
            </div>
          </div>
          {/* <!-- sidebar menu --> */}
          <ul className="sidebar-menu" data-widget="tree">
            <li className="nav-devider"></li>
            <li className="active">
              <Link to="/dashboard">
                <i className="fa fa-dashboard"></i> <span>Dashboard</span>
              </Link>
            </li>

            <li className="treeview">
              <Link to="#">
                <i className="fa fa-th"></i>
                <span>User Management</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-right pull-right"></i>
                </span>
              </Link>

              <ul className="treeview-menu">
                {CheckAccess("permission group", "index") && (
                  <li>
                    <Link to="/user-management/permissions-group">
                      Permission Group
                    </Link>
                  </li>
                )}
                {CheckAccess("permissions", "index") && (
                  <li>
                    <Link to="/user-management/permissions">Permissions</Link>
                  </li>
                )}
                {CheckAccess("roles", "index") && (
                  <li>
                    <Link to="/user-management/roles">Roles</Link>
                  </li>
                )}
                {CheckAccess("user", "index") && (
                  <li>
                    <Link to="/admin">User</Link>
                  </li>
                )}
              </ul>
            </li>
            <li className="treeview">
              <Link to="#">
                <i className="fa fa-th-list"></i>
                <span>CMS</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-right pull-right"></i>
                </span>
              </Link>
              <ul className="treeview-menu">
                {CheckAccess("menu", "index") && (user?.user_role?.name !== 'Guest Contributor') && (
                  <li>
                    <Link to="/breaking-news">
                      <i className="fa fa-th-list"></i>
                      <span>Breaking News</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {CheckAccess("menu", "index") && (
                  <li>
                    <Link to="/menu">
                      <i className="fa fa-th-list"></i>
                      <span>Menu</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {CheckAccess("pages", "index") && (
                  <li>
                    <Link to="/pages">
                      <i className="fa fa-th-list"></i>
                      <span>Pages</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {CheckAccess("section", "index") && (
                  <li>
                    <Link to="/section">
                      <i className="fa fa-th-list"></i>
                      <span>Section</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {CheckAccess("collections", "index") && (
                  <li>
                    <Link to="/collection">
                      <i className="fa fa-th-list"></i>
                      <span>Collections</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {CheckAccess("tags", "index") && (
                  <li>
                    <Link to="/tags">
                      <i className="fa fa-th-list"></i>
                      <span>Tags</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {CheckAccess("story", "index") && (
                  <li>
                    <Link to="/story">
                      <i className="fa fa-th-list"></i>
                      <span>Story</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {CheckAccess("rssFeed", "index") && (
                  <li>
                    <Link to="/rss-feed">
                      <i className="fa fa-th-list"></i>
                      <span>Rss Feed</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {CheckAccess("rssFeed", "index") && (
                  <li>
                    <Link to="/rss-listing">
                      <i className="fa fa-th-list"></i>
                      <span>Rss Listing</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {/* {CheckAccess("story", "index") && (
                  <li>
                    <Link to="/web-story">
                      <i className="fa fa-th-list"></i>
                      <span>Web Story</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {CheckAccess("story", "index") && (
                  <li>
                    <Link to="/video-story">
                      <i className="fa fa-th-list"></i>
                      <span>Video Story</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {CheckAccess("story", "index") && (
                  <li>
                    <Link to="/photo-story">
                      <i className="fa fa-th-list"></i>
                      <span>Photo Story</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )} */}
              </ul>
            </li>

            {/* <li className="treeview">
              <Link to="#">
                <i className="fa fa-th-list"></i>
                <span>Master</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-right pull-right"></i>
                  </span>
              </Link>
              <ul className="treeview-menu">
              <li>
                  <Link to="/attribute">
                  <i className="fa fa-th-list"></i>
                    <span>Attribute</span>
                    <span className="pull-right-container">
                    </span>
                  </Link>
                  </li>
                  </ul>
                </li> */}

            {/* USER MANAGEMENT */}
            {/* <Accordion defaultActiveKey="" className="treeview">
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  className="ac-btn-bx"
                  onClick={(e) => {
                    e?.preventDefault();
                    setIsActive("userManagement");
                  }}
                >
                  <a href="#" className="accordion-btn">
                    <i className="fa fa-th"></i>
                    <span>User Management</span>
                    <span className="pull-right-container custom-right ">
                      <i
                        className={
                          isActive === "userManagement"
                            ? "fa fa-angle-right pull-right icon-rotate"
                            : "fa fa-angle-right pull-right rotate-reverse"
                        }
                      ></i>
                    </span>
                  </a>
                </Accordion.Header>
                <Accordion.Body className="py-0">
                  <ul className="treeview-menu d-block">
                    {CheckAccess("permission group", "index") && (
                      <li>
                        <Link to="/user-management/permissions-group">
                          Permission Group
                        </Link>
                      </li>
                    )}
                    {CheckAccess("permissions", "index") && (
                      <li>
                        <Link to="/user-management/permissions">
                          Permissions
                        </Link>
                      </li>
                    )}
                    {CheckAccess("roles", "index") && (
                      <li>
                        <Link to="/user-management/roles">Roles</Link>
                      </li>
                    )}
                    {CheckAccess("user", "index") && (
                      <li>
                        <Link to="/admin">User</Link>
                      </li>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            {/* cms */}
            {/* <Accordion defaultActiveKey="" className="treeview">
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  className="ac-btn-bx"
                  onClick={(e) => {
                    e?.preventDefault();
                    setIsActive("cms");
                  }}
                >
                  <a href="#" className="accordion-btn">
                    <i className="fa fa-th-list"></i> <span>CMS</span>
                    <span className="pull-right-container custom-right ">
                      <i
                        className={
                          isActive === "cms"
                            ? "fa fa-angle-right pull-right icon-rotate"
                            : "fa fa-angle-right pull-right rotate-reverse"
                        }
                      ></i>
                    </span>
                  </a>
                </Accordion.Header>
                <Accordion.Body className="py-0">
                  <ul className="treeview-menu d-block">
                    {CheckAccess("menu", "index") && (
                      <li>
                        <Link to="/menu">
                          <i className="fa fa-th-list"></i>
                          <span>Menu</span>
                          <span className="pull-right-container"></span>
                        </Link>
                      </li>
                    )}
                    {CheckAccess("pages", "index") && (
                      <li>
                        <Link to="/pages">
                          <i className="fa fa-th-list"></i>
                          <span>Pages</span>
                          <span className="pull-right-container"></span>
                        </Link>
                      </li>
                    )}
                    {CheckAccess("section", "index") && (
                      <li>
                        <Link to="/section">
                          <i className="fa fa-th-list"></i>
                          <span>Section</span>
                          <span className="pull-right-container"></span>
                        </Link>
                      </li>
                    )}
                    {CheckAccess("collections", "index") && (
                      <li>
                        <Link to="/collection">
                          <i className="fa fa-th-list"></i>
                          <span>Collections</span>
                          <span className="pull-right-container"></span>
                        </Link>
                      </li>
                    )}
                    {CheckAccess("story", "index") && (
                      <li>
                        <Link to="/story">
                          <i className="fa fa-th-list"></i>
                          <span>Story</span>
                          <span className="pull-right-container"></span>
                        </Link>
                      </li>
                    )}
                   {CheckAccess("story", "index") && <li>
                      <Link to="/story">
                        <i className="fa fa-th-list"></i>
                        <span>Web Story</span>
                        <span className="pull-right-container"></span>
                      </Link>
                    </li>}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
            {/* SETTINGS */}
            {/* <Accordion defaultActiveKey="" className="treeview">
              <Accordion.Item>
                <Accordion.Header
                  className="ac-btn-bx"
                  onClick={(e) => {
                    e?.preventDefault();
                    setIsActive("settings");
                  }}
                >
                  <a href="#" className="accordion-btn">
                    <i className="fa fa-cog"></i> <span>Settings</span>
                    <span className="pull-right-container custom-right ">
                      <i
                        className={
                          isActive === "settings"
                            ? "fa fa-angle-right pull-right icon-rotate"
                            : "fa fa-angle-right pull-right rotate-reverse"
                        }
                      ></i>
                    </span>
                  </a>
                </Accordion.Header>
                <Accordion.Body className="py-0">
                  <ul className="treeview-menu d-block">
                    <li>
                      <Link to="/settings/contacts">Contacts</Link>
                    </li>
                    <li>
                      <Link to="/settings/social-links">Social Links</Link>
                    </li>
                    <li>
                      <Link to="/settings/push-notification/manage">
                        Push Notifications
                      </Link>
                    </li>
                    <li>
                      <Link to="#">Email</Link>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}

            <li className="treeview">
              <a href="#">
                <i className="fa fa-cog"></i> <span>Settings</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-right pull-right"></i>
                </span>
              </a>
              <ul className="treeview-menu">
                <li>
                  <Link to="/settings/contacts">Contacts</Link>
                </li>
                <li>
                  <Link to="/settings/social-links">Social Links</Link>
                </li>
                <li>
                  <Link to="/settings/push-notification/manage">
                    Push Notifications
                  </Link>
                </li>
                <li>
                  <Link to="#">Email</Link>
                </li>
                <li>
                  <Link to="/settings/tag">Script Tag</Link>
                </li>

                <li>
                  <Link to="/settings/general-settings">General Settings</Link>
                </li>

                <li>
                  <Link to="/settings/integrations">Integrations</Link>
                </li>
              </ul>
            </li>
            {/* old */}
            {/* <li className="treeview">
              <Link to="#">
                <i className="fa fa-th-list"></i>
                <span>Menu Bar</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-right pull-right"></i>
                </span>
              </Link>
              <ul className="treeview-menu">
                <li>
                  <Link to="/menu/create">Create Menu</Link>
                </li>
                <li>
                  <Link to="/menu/manage">Manage Menu</Link>
                </li>
                <li>
                  <Link to="/menu/update">Update Menu</Link>
                </li>
              </ul>
            </li> */}
            {/* <li>
              <Link to="/static">
                <i className="fa fa-puzzle-piece"></i>
                <span>Static </span>
              </Link>
            </li> */}

            {CheckAccess("opinion poll", "index") && (
              <li>
                <Link to="/opinion-polls">
                  <i className="fa fa-bar-chart"></i>
                  <span>Opinion Poll </span>
                </Link>
              </li>
            )}

            <li className="treeview">
              <Link to="#">
                <i className="fa fa-th-list"></i>
                <span>Reports</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-right pull-right"></i>
                </span>
              </Link>
              <ul className="treeview-menu">
                {user?.user_role?.name === 'Admin' && (
                  <li>
                    <Link to="/story-report">
                      <i className="fa fa-th-list"></i>
                      <span>Story Report</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}
                {user?.user_role?.name === 'Admin' && (
                  <li>
                    <Link to="/author-report">
                      <i className="fa fa-th-list"></i>
                      <span>Author Report</span>
                      <span className="pull-right-container"></span>
                    </Link>
                  </li>
                )}

              </ul>
            </li>


            {/* <li>
            <Link to="/categories">
              <i className="fa fa-dashboard"></i> <span>Category</span>
            </Link>
          </li> */}

            {/* <li>
            <Link to="/contact-requests">
              <i className="fa fa-envelope"></i>
              <span>Contact Requests</span>
            </Link>
          </li>
          <li>
            <Link to="/our-works">
              <i className="fa fa-briefcase"></i>
              <span>Our Work</span>
            </Link>
          </li>
          <li>
            <Link to="/seo-content">
              <i className="fa fa-search"></i>
              <span>SEO</span>
            </Link>
          </li>
          <li>
            <Link to="/e-book-subscribers">
              <i className="fa fa-bell-o"></i>
              <span>eBook Subscribers</span>
            </Link>
          </li>
          <li className="treeview">
            <a href="#">
              <i className="fa fa-cog"></i> <span>Career</span>
              <span className="pull-right-container">
                <i className="fa fa-angle-right pull-right"></i>
              </span>
            </a>
            <ul className="treeview-menu">
              <li><Link to="/career/openings">Openings</Link></li>
              <li><Link to="/settings/job-applications">Job Applications</Link></li>
            </ul>
          </li>
          <li>
            <Link to="/blogs">
              <i className="fa fa-bell"></i>
              <span>Blog</span>
            </Link>
          </li>
          <li>
            <Link to="/news-letters">
              <i className="fa fa-bell"></i>
              <span>Subscribers</span>
            </Link>
          </li> */}
          </ul>
        </section>
      </aside>
    </>
  );
};

export default Menu;
