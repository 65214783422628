// config.js
const config = {
  // apiUrl: "https://raftaar-api.jithvar.com",
  apiUrl: "https://api.raftaar.in",
  // apiUrl: "http://localhost:3011",


  fileUrl: 'https://s3.ap-south-1.amazonaws.com/files.raftaar.in' || "https://api.raftaar.in",

  migratedFileUrl: "https://media.assettype.com",

  frontendUrl: 'https://raftaar.in',

  s3Key: 'AKIAZQ3DNUA4GUAILEC3',
  s3SecretAccessKey: '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',
  awsRegion: 'ap-south-1',
  awsBucket: 'files.raftaar.in',

  awsBaseUrl: 'https://s3.ap-south-1.amazonaws.com/files.raftaar.in',
 
  metaTitleLen: 90,
  metaDescriptionLen: 160
};

export default config;
