import React, { useEffect, useState } from 'react'
import config from '../../../config'

const Index = () => {
    const [collections, setCollections] = useState([])

    const fetchCollections = async () => {
        const url = `${config.apiUrl}/collection/rss/listing/index`
        const data = await (await fetch(url)).json()
        setCollections(data?.data)
    }

    useEffect(() => {
        const getData = async () => {
            await fetchCollections()
        }
        getData()
    }, [])


    return (
        <>
            <div style={{ backgroundColor: '#f9f9f9' }}>
                <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
                    <div className="content">
                        <div className="card col-md-12 m-auto p-30">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-hover capitalize-text">
                                        <thead>
                                            <tr>
                                                <th>Collection Title</th>
                                                <th>Collection Slug Preview</th>
                                                <th>Rss Url</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                collections?.map((collection, index) => (
                                                    <tr key={index}>
                                                        <td>{collection?.title}</td>
                                                        <td>{collection?.slug_preview}</td>
                                                        <td style={{ textTransform: 'none' }}><a href={config?.apiUrl + '/collection/rss/' + collection?.slug_preview?.replace(/\//g, '-') + '.xml'} target='_blank'>{config?.apiUrl + '/collection/rss/' + collection?.slug_preview?.replace(/\//g, '-') + '.xml'}</a></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index